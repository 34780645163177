import firebase from 'firebase';

var config = {
  apiKey:            process.env.VUE_APP_FIREBASE_APIKEY,
  authDomain:        process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
  projectId:         process.env.VUE_APP_FIREBASE_PROJECTID,
  storageBucket:     process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGINGSENDERID,
  appId:             process.env.VUE_APP_FIREBASE_APPID,
  measurementId:     process.env.VUE_APP_FIREBASE_MEASUREMENTID
};

// Initialize Firebase
firebase.initializeApp(config);
firebase.analytics();


export default firebase;