import i18n from '@/i18n'

/* eslint-disable */
const defaultState = { 
  color: {
    name: 'purple',
    hex: '#6800FF'
  },
  idiom: 'pt'
}

export default {
  namespaced: true,
  state: { ...defaultState },
  getters: {
    app: state => state
  },
  actions: {
    getColor ({ commit, rootGetters }) {
      if (!rootGetters['user/user']) {
        commit('setColor', defaultState.color)
        return
      }
  
      const colors = { red: '#F60000', orange: '#FF8C00',  yellow: '#FFCB00', green: '#41EC41', lightblue: '#00B2F8', indigo: '#000DC5', purple: '#6800FF' }
      const userColor = rootGetters['user/user'].settings.color
      
      if (userColor === 'random') {
        const ramdomPosition = ~~(Math.random() * 7)

        commit('setColor', {
          name: Object.keys(colors)[ramdomPosition],
          hex: Object.values(colors)[ramdomPosition]
        })

        return
      }
      commit('setColor', {
        name: userColor,
        hex: colors[userColor]
      })
    },
    changeIdiom ({ commit, dispatch, rootGetters }, idiom) {
      i18n.locale = idiom
      commit('setIdiom', idiom)

      const user = rootGetters['user/user']
      if (!user || user.settings.idiom === idiom) 
        return
      
      user.settings.idiom = idiom
      dispatch('user/updateUser', user, { root: true })
    }
  },
  mutations: {
    setColor: (state, color) => { state.color = color },
    setIdiom: (state, idiom) => { state.idiom = idiom },
    reset: state => { state = defaultState }
  }
}