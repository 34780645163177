import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from 'vuetify/lib/locale/en'
import pt from 'vuetify/lib/locale/pt'

Vue.use(VueI18n)

const messages = {
  pt: {
    ...require('@/languages/pt.json'),
    $vuetify: pt,
  },
  en : {
    ...require('@/languages/en.json'),
    $vuetify: en,
  }
}

export function getBrowserLocale(options = {}) {
  const defaultOptions = { countryCodeOnly: false }

  const opt = { ...defaultOptions, ...options }

  const navigatorLocale =
    navigator.languages !== undefined
      ? navigator.languages[0]
      : navigator.language

  if (!navigatorLocale) {
    return undefined
  }

  const trimmedLocale = opt.countryCodeOnly
    ? navigatorLocale.trim().split(/-|_/)[0]
    : navigatorLocale.trim()
  
  return trimmedLocale
}

export default new VueI18n({
  locale: getBrowserLocale({ countryCodeOnly: true }) || 'pt',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'pt',
  messages,
})
