import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from 'vuetify/lib/util/colors'
import i18n from '@/i18n'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      light: {
        primary: colors.deepPurple.accent4,
        secondary: '#ffd700',
      },
      options: { customProperties: true }
    },
  },
});
