import Vue from 'vue'
import App from '@/App.vue'
import vuetify from '@/plugins/vuetify'
import router from '@/router'
import Vuex from 'vuex'
import VueMask from 'v-mask'
import store from '@/store/store'
import i18n from '@/i18n'
import { EventBus } from '@/services/event-bus'
import VueSocialSharing from 'vue-social-sharing'

Vue.config.productionTip = false

Vue.prototype.$innerWidth = innerWidth
EventBus.$on('resize', () => {
  Vue.prototype.$innerWidth = innerWidth
});

Vue.use(Vuex)
Vue.use(VueMask)
Vue.use(VueSocialSharing)

// Preventing app recreating due firebase.auth.onAuthStateChanged at and initializeAuthStateWatcher at src/modules/auth.store.js
let application
store.dispatch('auth/initializeAuthStateWatcher').then(() => {
  if (!application) {
    application = new Vue({
      vuetify,
      store,
      router,
      i18n,
      render: h => h(App)
    }).$mount('#app')
  }
})