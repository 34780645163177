import firebase from '@/firebase'
import { showSnackBar } from '@/services/snackbar'

export default {
  namespaced: true,
  state: {
    user: null
  },
  getters: {
    user: state => state.user
  },
  actions: {
    async createUser ({ commit, rootGetters }, user) {
      user.settings.color = 'purple'
      user.settings.idiom = rootGetters['app/app'].idiom
      
      await firebase
        .firestore()
        .collection('user')
        .doc(user.id)
        .set(user)
        .then(() => commit('setUser', user))
        .catch(err => {
          console.error(err);
          showSnackBar({
            message: `Failed to create your user. Please contact site's administrator.`, //this.$t(err.message),
              config: {
                color: 'red',
                timeout: 3500
              }
            })
        })
    },
    async getUser({ commit }, userID) {
      await firebase.firestore()
        .collection('user')
        .doc(userID)
        .get()
        .then(snap => commit('setUser', snap.data()))
    },
    async updateUser ({ commit, getters }, user) {
      await firebase
        .firestore()
        .collection('user')
        .doc(getters.user.id)
        .update(user)
        .then(() => commit('setUser', { ...getters.user, ...user }))
        .catch(err => {
          console.log(err)
          showSnackBar({
            message: `Failed to update your data. Please contact site's administrator.`, //this.$t(err.message),
              config: {
                color: 'red',
                timeout: 3500
              }
            })
          }
        )
    },
    async removeUser ({ commit }, userID) {
      await firebase 
        .firestore()
        .collection('user')
        .doc(userID)
        .delete()
        .then(() => commit('reset'))
        .catch(() => 
          showSnackBar({
            message: `Failed to remove your user. Please try again or contact site's administrator.`, //this.$t(err.message),
              config: {
                color: 'red',
                timeout: 3500
              }
            })
        )
    }
  },
  mutations: {
    setUser: (state, user) => { state.user = user },
    reset: state => { state.user = null }
  }
}
  