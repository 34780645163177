import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/store'

Vue.use(Router)

const routes = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home'),
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/views/Profile'),
    meta: {
      requireAuthentication: true
    }
  },
  {
    path: '/liked',
    name: 'LikedPosts',
    component: () => import('@/views/LikedPosts'),
    meta: {
      requireAuthentication: true
    }
  },
  {
    path: '/moment-post',
    name: 'MomentPost',
    component: () => import('@/views/MomentPost'),
  },
  {
    path: '/collaborate',
    name: 'Collaborate',
    component: () => import('@/views/Collaborate'),
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('@/views/Settings'),
    meta: {
      requireAuthentication: true
    }
  },
  {
    path: '/terms-and-policy',
    name: 'TermsAndPolicy',
    component: () => import('@/views/TermsAndPolicy'),
  },
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
})

router.beforeEach(async (to, from, next) => {
  const authRoute = to.matched.some(record => record.meta.requireAuthentication)

  if (authRoute && !store.state.user.user) {
    next('/')
    return
  }

  setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 500)
  next()
})

export default router


