<template>
  <v-app>
    <Navbar :user='user' :color="$store.getters['app/app'].color.name"/>
    <v-main>
      <v-container>
        <router-view />
      </v-container>
      <Footer :color="$store.getters['app/app'].color.name" />
      <SnackBar />
    </v-main>
  </v-app>
</template>
<style lang="scss">
  @import '@/assets/scss/_global.scss';

</style>
<script>
import { EventBus } from '@/services/event-bus'
export default {
  name: 'App',
  components: {
    Navbar:   () => import('@/components/ui/Navbar'),
    SnackBar: () => import('@/components/ui/SnackBar'),
    Footer:   () => import('@/components/ui/Footer')
  },
  data: () => ({
    user: null,
    color: ''
  }),
  created() {
    window.addEventListener("resize", this.onResize)
  },
  async mounted () {    
    this.user = this.$store.getters['user/user']

    this.changeVuetifyThemeColor()
    
    this.$store.watch(
      state => state.user.user,
      changedUser => { 
        this.user = changedUser
        this.$store.dispatch('app/getColor')
        this.changeVuetifyThemeColor()
      },
      { deep: true }
    );
  },
  methods: {
    onResize () {
      EventBus.$emit('resize', {})
    },
    changeVuetifyThemeColor () {
      this.$vuetify.theme.themes.light.primary = this.$store.getters['app/app'].color.hex
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize)
  },
};
</script>
