const setPostLikes = (posts, user) => 
  posts.map(post => { 
    post.liked = false;

    if (!user?.likedPosts)
      return post    

    user.likedPosts.forEach(postID => { 
      if (postID === post.id)
        post.liked = true 
    })
    
    return post
  })


const sortPostsByDateAndEmptiness = (posts) => 
  posts.sort((a, b) => 
    !a.createdAt                ?  1 : 
    !b.createdAt                ? -1 : 
    a.createdAt === b.createdAt ?  0 :
    a.createdAt >   b.createdAt ? -1 : 1
  )


import firebase from '@/firebase'

export default {
  namespaced: true,
  state: {
    posts: [],
    watchedTimes: 0, // Implemented due to the first watch happening without any changes in the collection.
  },
  getters: {
    posts: state => state.posts,
    watchedTimes: state => state.watchedTimes
  },
  actions: {
    async getPosts({ commit, rootGetters }) {
      await firebase
        .firestore()
        .collection('post')
        .get()
        .then(snap => {
          if (!snap.empty) {
            const posts = snap.docs.map(doc => doc.data())
            const user = rootGetters['user/user']
            commit('setPosts', sortPostsByDateAndEmptiness(setPostLikes(posts, user)))
          }
        })
    },
    watchPosts({ commit, getters, rootGetters }) {
      firebase
        .firestore()
        .collection('post')
        .onSnapshot(snap => {
          if (getters.watchedTimes === 0) {
            commit('incrementWatchedTimes', getters.watchedTimes)
            return
          }

          const posts = snap.docs.map(doc => doc.data())
          const user = rootGetters['user/user']
          commit('setPosts', sortPostsByDateAndEmptiness(setPostLikes(posts, user)))
        })
    }
  },
  mutations: {
    setPosts: (state, posts) => { state.posts = posts },
    incrementWatchedTimes: state => { state.watchedTimes++ },
    reset: state => { state.posts = [] },    
  }
}
  