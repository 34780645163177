/* eslint-disable */
import firebase from '@/firebase'
import { showSnackBar } from '@/services/snackbar'
import { getBrowserLocale } from '@/i18n'

export default {
  namespaced: true,
  state: {
    auth: {
      uid: ''
      // tokens and etc
    }
  },
  getters: {
    auth: state => state.auth
  },
  actions: {
    async initializeAuthStateWatcher ({ commit, dispatch, rootGetters }) {
      await firebase 
        .auth()
        .onAuthStateChanged(async userAuthentication => {
          commit('setAuth', userAuthentication !== null ? userAuthentication.uid : '' )

          if (!userAuthentication) {
            commit('user/reset', null, { root: true }) 
            dispatch('app/changeIdiom', getBrowserLocale({ countryCodeOnly: true }), { root: true })
            return
          }          

          await dispatch('user/getUser', userAuthentication.uid, { root: true }).then()
          dispatch('app/changeIdiom', rootGetters['user/user'].settings.idiom, { root: true })
        })
    },
    async signUp({ dispatch }, user) {
      await firebase.auth()
        .createUserWithEmailAndPassword(user.email, user.password)
        .then(async res => {
          delete user.password
          
          await dispatch('user/createUser', {
            id: res.user.uid,
            ...user
          },
          { root: true })

          showSnackBar({
            message: 'You have successfully signed up! Welcome.',
              config: {
                color: '#13b719',
                timeout: 3500
              }
          })
        })
        .catch(err => 
          showSnackBar({
            message: err.code,
              config: {
                color: 'red',
                timeout: 3500
              }
          })
        )
    },
    async signIn({ dispatch, rootGetters }, credentials) {
      await firebase.auth()
        .signInWithEmailAndPassword(credentials.email, credentials.password)
        .then(async res => {
          await dispatch('user/getUser', res.user.id, { root: true })
          
          showSnackBar({
            message: 'You have signed in! Welcome.',
              config: {
                color: '#13b719',
                timeout: 3500
              }
            })
        })
        .catch(err => {
          console.log(err)
          showSnackBar({
            message: err.code, 
            config: {
              color: 'red',
              timeout: 3500
            }
          })
        })
    },
    async socialSingUpOrIn({ dispatch, rootGetters }, settings) {
      let provider
      
      if (settings.provider === 'facebook') {
        provider = new firebase.auth.FacebookAuthProvider()
        provider.addScope('email')
      }

      if (settings.provider === 'google') {
        provider = new firebase.auth.GoogleAuthProvider()
        provider.addScope('https://www.googleapis.com/auth/userinfo.email')
        provider.addScope('https://www.googleapis.com/auth/userinfo.profile')
      }

      await firebase
        .auth()
        .signInWithPopup(provider)
        .then(async res => {
          if (settings.intent === 'signUp') {
            await dispatch('user/getUser', res.user.uid, { root: true }) 
            
            if (rootGetters['user/user']) {
              showSnackBar({
                message: 'This account is already signed up. Welcome back!',
                config: {
                  color: 'orange',
                  timeout: 3500
                }
              })
              return
            }

            await dispatch('user/createUser', {
              id: res.user.uid,
              email: res.user.email,
              name: res.user.displayName
            },
            { root: true })
          }
          
          if (settings.intent === 'signIn' && rootGetters['user/user']?.likedPosts) 
            await dispatch('post/updatePostsLike', rootGetters['user/user'].likedPosts, { root: true }) 
          
          showSnackBar({
            message:  `You have ${settings.intent === 'signUp' ? 'successfully signed up!' : 'signed in!'} Welcome.`,
            config: {
              color: '#13b719',
              timeout: 3500
            }
          })
        })
        .catch(err =>
            showSnackBar({
              message: err.code,
              config: {
                color: 'red',
                timeout: 3500
              }
            })
        )
    },
    async signOut({ dispatch }) {
      await firebase.auth()
        .signOut()
        .then(() => {
          showSnackBar({
            message: 'You have signed out.',
            config: {
              color: '#13b719',
              timeout: 3500
            }
          })
        })
        .catch(() => 
          showSnackBar({
            message: `Failed to sign out. Please try again or contact site's administrator.`,
            config: {
              color: 'red',
              timeout: 3500
            }
          })
        )
    },
    async resetPassword({ commit }, email) {
      await firebase.auth()
        .sendPasswordResetEmail(email)
        .then(() =>
          showSnackBar({
            message: 'Email sent. If necessary, verify the span box or junk boxes.',
            config: {
              color: '#13b719',
              timeout: 3500
            }
          })
        )
        .catch(() => 
          showSnackBar({
            message: `Failed to initialize the redefinition. Please, try again or contact site's administrator.`,
            config: {
              color: 'red',
              timeout: 3500
            }
          })
        )
    },
    async changePassword({ commit }, user) {    
      await firebase.auth()
        .currentUser
        .updatePassword(user.newPassword)
        .then(() => {
          showSnackBar({
            message: 'Your password has been successfully updated!',
            config: {
              color: '#13b719',
              timeout: 3500
            }
          })
          return true
        })
        .catch(() => {
          showSnackBar({
            message: `Failed to update your password. Please, try again or contact site's administrator.`,
            config: {
              color: 'red',
              timeout: 3500
            }
          })
          return false
        })   
    },
    async removeUser({ commit }) {
      firebase
        .auth()
        .currentUser
        .delete()
        .then(() => commit('reset'))
        .catch(() => 
          showSnackBar({
            message: `Failed to erase your profile. Please, try again or contact site's administrator.`,
            config: {
              color: 'red',
              timeout: 3500
            }
          })
        )
    }    
  },
  mutations: {
    setAuth: (state, uid) => { state.auth.uid = uid },
    reset: state => { state.auth.uid = '' },
  }
}